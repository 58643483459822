<template>
  <v-list>
    <v-list-item class="pl-0 pr-0">
      <v-list-item-avatar avatar :color="evaluatee.profile.imageUrl ? 'white' : 'primary'" class="mr-3" :size="60">
        <v-img
          style="border:2px solid #333"
          v-if="evaluatee.profile.imageUrl"
          :src="evaluatee.profile.imageUrl"
          :lazy-src="evaluatee.profile.imageUrl"
        >
          <template v-slot:placeholder>
            <v-layout fill-height align-center justify-center ma-0>
              <v-progress-circular indeterminate color="grey lighten-5" />
            </v-layout>
          </template>
        </v-img>
        <span class="white--text font-weight-bold text-h5" v-else>
          {{ evaluatee.firstName.substring(0, 1).toUpperCase() }}
          {{ evaluatee.lastName.substring(0, 1).toUpperCase() }}
        </span>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title class="title"> {{ evaluatee.firstName }} {{ evaluatee.lastName }} </v-list-item-title>
        <v-list-item-subtitle class="body-2">
          {{ (evaluatee.employment.branch && evaluatee.employment.branch.name) || "-" }}
        </v-list-item-subtitle>
        <v-list-item-subtitle>
          {{ (evaluatee.employment.jobInfo && evaluatee.employment.jobInfo.name) || "-" }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
  export default {
    name: "user-profile",
    props: {
      evaluatee: Object
    },
    data: () => ({}),
    methods: {}
  };
</script>

<style scoped></style>
